'use client';

import useUpdateStore from '@/hooks/useUpdateStore';

const ReduxContainer = ({ children, initialReduxState }) => {
  useUpdateStore(initialReduxState);
  return children;
};

export default ReduxContainer;
