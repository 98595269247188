import { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setBanners } from '@/redux/banners/bannersReducer';
import { setFaqs } from '@/redux/faqs/faqsReducer';
import { setHelps } from '@/redux/help/helpReducer';
import { setNav } from '@/redux/navBar/navBarReducer';
import { setNewsletter } from '@/redux/newsletter/newsletterReducer';
import { setStoreBranches } from '@/redux/storebranches/storebranchesReducer';
import { setMap } from '@/redux/map/mapReducer';
import { setProducts } from '@/redux/products/productsReducer';
import { setProductSelected } from '@/redux/productDetail/productDetailReducer';
import { setCheckout } from '@/redux/checkout/checkoutReducer';
import { setTracking } from '@/redux/tracking/trackingReducer';
import { setStore } from '@/redux/store/storeReducer';
import { stateIvs } from '@/redux/streaming';
import { setMachineInformation } from '@/redux/machineInformation/machineInformationReducer';
import { setSsrInfo } from '@/redux/ssrInfo/ssrInfoReducer';
import { setCart } from '@/redux/cart/cartReducer';

const SETTERS = {
  banners: setBanners,
  faqs: setFaqs,
  help: setHelps,
  navBar: setNav,
  newsletter: setNewsletter,
  storebranches: setStoreBranches,
  map: setMap,
  cart: setCart,
  products: setProducts,
  productDetail: setProductSelected,
  checkout: setCheckout,
  tracking: setTracking,
  store: setStore,
  streaming: stateIvs,
  machineInformation: setMachineInformation,
  ssrInfo: setSsrInfo
};

const useUpdateStore = (initialReduxState = {}) => {
  const dispatch = useDispatch();
  const isInitialized = useRef(false);
  // TODO: Ver si se puede hacer sin useLayoutEffect
  // se usa esto por que en pnpm dev se crea bucle infinito
  if (!isInitialized.current && process.env.NODE_ENV !== 'development') {
    isInitialized.current = true;
    Object.keys(initialReduxState).forEach((slice) => {
      if (SETTERS[slice]) {
        dispatch(SETTERS[slice](initialReduxState[slice]));
      }
    });
  }

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      Object.keys(initialReduxState).forEach((slice) => {
        if (SETTERS[slice]) {
          dispatch(SETTERS[slice](initialReduxState[slice]));
        }
      });
    }
  }, []);
};
export default useUpdateStore;
